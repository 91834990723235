/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
a {
	text-decoration: none;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}

/* END OF RESET CSS */

/* CSS VARIABLES */

:root {
	--beige: #EFE9E2;
	--white: #ffffff;
	--blue: #5A92AB;
	--red: #8E1B33;
	--black: #000000;
	--link-hover: #B89461;
}

.content {
    padding-top: 70px;
	width: 100%;
}

.main {
	background-color: var(--beige);
}

.title-pages{
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	margin: 5rem 0 2rem ;
	font-family: de-walpergens-pica-sc, sans-serif ;
	font-size: 1.8rem;
}

/* HOMEPAGE MOBILE */

.imagesection {
    height: calc(100vh - 70px);
    background-image: url('../img/background-mobile.png') ;
    background-size: cover;
}

.opacity{
    height: 100%;
    width: 100%;
    background-color: rgb(0, 0, 0, 0.4);
	display: flex;
	justify-content: space-between;
	flex-direction: column;
}

.home-heading {
	display: flex;
	flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.space-down{
	height: 1rem;
	margin-bottom: 70px;
}

.arrows-cont {
	width: 100%;
	display: flex;
	justify-content: center;
}

.title-home{
    color: var(--white);
    padding-bottom: 2rem;
    font-size: 3rem;
    font-family: de-walpergens-pica-sc, sans-serif;
}

.title-home-desktop{
	display: none;
}

.second-title{
    font-size: 2.5rem;
}

.arrowsdown {
	color: var(--white);
}

/* Arrow animation */

.shake-vertical {
	-webkit-animation: shake-vertical 2.5s cubic-bezier(0.455, 0.030, 0.515, 0.955) both;
	        animation: shake-vertical 2.5s cubic-bezier(0.455, 0.030, 0.515, 0.955) both;
}

/* ----------------------------------------------
 * Generated by Animista on 2022-12-7 13:11:30
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation shake-vertical
 * ----------------------------------------
 */
@-webkit-keyframes shake-vertical {
	0%,
	100% {
	  -webkit-transform: translateY(0);
			  transform: translateY(0);
	}
	10%,
	30%,
	50%,
	70% {
	  -webkit-transform: translateY(-8px);
			  transform: translateY(-8px);
	}
	20%,
	40%,
	60% {
	  -webkit-transform: translateY(8px);
			  transform: translateY(8px);
	}
	80% {
	  -webkit-transform: translateY(6.4px);
			  transform: translateY(6.4px);
	}
	90% {
	  -webkit-transform: translateY(-6.4px);
			  transform: translateY(-6.4px);
	}
}
@keyframes shake-vertical {
	0%,
	100% {
	  -webkit-transform: translateY(0);
			  transform: translateY(0);
	}
	10%,
	30%,
	50%,
	70% {
	  -webkit-transform: translateY(-8px);
			  transform: translateY(-8px);
	}
	20%,
	40%,
	60% {
	  -webkit-transform: translateY(8px);
			  transform: translateY(8px);
	}
	80% {
	  -webkit-transform: translateY(6.4px);
			  transform: translateY(6.4px);
	}
	90% {
	  -webkit-transform: translateY(-6.4px);
			  transform: translateY(-6.4px);
	}
}

/* End arrow animation */

.sub-title-mobile{
	display: flex;
	justify-content: center;
	align-self: center;
	font-family: de-walpergens-pica-sc, sans-serif;
	font-size: 2.5rem;
	margin: 2rem 0;
}

.sub-title{
	display: flex;
	justify-content: center;
	align-self: center;
	font-family: de-walpergens-pica-sc, sans-serif;
	font-size: 2.5rem;
	margin: 2rem 0;
}

.ronni-home{
	width: 85%;
	display: flex;
	margin: auto;
	box-shadow: 3px 3px 8px 0 rgb(0, 0, 0, 0.5);
}

.bold-text{
	font-family: europa;
	font-weight: 500;
}

.home-text-p{
	font-family: europa;
	font-weight: 300;
	line-height: 1.3rem;
	font-size: 1.2rem;
	margin: 2rem 1.5rem 0 1.5rem;
}

.buttons-container{
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	margin-top: 2.5rem;
}

.button-home{
	background-color: var(--blue);
	color: var(--white);
	display: flex;
	justify-content: center;
	align-items: center;
	font-family: europa;
	font-weight: 200;
	font-size: 1.7rem;
	border: none;
	border-radius: 50px;
	width: 70%;
	height: 4rem;
	margin-bottom: 1.5rem;
}

/* Footer (mobile) */

.footer-main {
	background-color: var(--red);
	display: flex;
	flex-direction: column;
	justify-content: center;
	color: var(--white);
	font-family: de-walpergens-pica, sans-serif;
}

.footer-top {
	display: flex;
	width: 100%;
}

.footer-hours-head, .footerloc-heading, .footercontact-heading, .footersome-heading {
	font-size: 1.5em;
	margin-bottom: 0.5em;
}

.footersome-heading {
	display: none;
}

.footer-bottom {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 0.5em 0;
}

.footer-middle {
	display: flex;
	flex-direction: column;
	width: 50%;
	padding: 0.6em;
}

.footer-location-cont {
	margin-bottom: 0.7em;
}

.footer-hours {
	width: 50%;
	padding: 0.6em;
}

.footer-contact-single {
	display: flex;
	align-items: center;
	margin-bottom: 0.2em;
}

.footer-some {
	display: flex;
	justify-content: center;
	width: 100%;
	color: var(--black);
}

.some {
	text-decoration: none;
	color: var(--white);
}

.someleft {
	margin-right: 0.2em;
}

.someright {
	margin-left: 0.2em;
}

.footercontact-text {
	font-size: 0.8em;
	color: var(--white);
	text-decoration: none;
	margin-left: 0.2em;
}

.footer-single-hour {
	margin-bottom: 0.3em;
}

.footerloc-text {
	margin-bottom: 0.3em;
}

.mobile-footer {
	display: block !important;
}

.desktop-footer {
	display: none !important;
}

/* PORTFOLIO PAGE */

.portfolio-btn {
	margin: 0.5em;
	color: var(--beige);
	background-color: var(--blue);
	border: none;
	border-radius: 25px;
	height: 2em;
	font-size: 1.2em;
	cursor: pointer;
	width: 30%;
}

#button1 {
	grid-area: btn1;
}

#button2 {
	grid-area: btn2;
}

#button3 {
	grid-area: btn3;
}

#button4 {
	grid-area: btn4;
}

.buttons-container-portfolio {
	display: flex;
	margin: 2em 1em;
}

.portfolio-content {
	margin-bottom: 2em;
}

/* Portfolio - image container */

.img-portf-cont {
	display: flex;
	justify-content: center;
}

.modalbox {
	width: 21em;
	height: 21em;
	display: flex;
	justify-content: center;
	align-items: center;
}

.portfolio-img {
	width: 8em;
	margin: auto;
	margin-bottom: 1em;
	cursor: pointer;
	box-shadow: 3px 3px 8px 0 rgb(0, 0, 0, 0.5);
}

.portfolio-img-container {
	color: var(--black);
	margin: 0 1em;
	display: grid; 
	grid-auto-columns: 1fr; 
	grid-template-rows: 1fr 1fr; 
	gap: 0px 0px; 
	grid-template-areas: 
	  ". ."
	  ". ."; 
}

.portfolio-full-img {
	margin: 0;
	width: 19em;
	box-shadow: 3px 3px 8px 0 rgba(155, 154, 154, 0.5);
}

/* PROCEDURES AND CARE PAGE */

.container-main-care{
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
}

.accordion-container{
	display: flex;
	flex-direction: column;
	width: 80%;
	margin-bottom: 7rem;
}

.accordion {
	margin: 2em 0;
	width: 800px;
  }
  
.accordion-title {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	cursor: pointer;
	background-color: var(--blue);
	color: var(--white);
	font-family: europa;
	font-weight: 300;
	font-size: 1.3em;
}
  
.accordion-title:hover {
	background-color: var(--blue);
}
  
.accordion-title, .accordion-content {
	padding: 1rem;
}
  
.accordion-content {
	background-color: var(--white);
	font-family: europa;
	font-weight: 300;
	font-size: 1.2em;
	line-height: 1.5rem;
}

.link-care{
	color: var(--blue);
	text-decoration: underline;
}

/* SOME SAY ABOUT US PAGE */

.title-somesay{
	margin: 4rem 0 1.5rem;
	font-size: 2.5rem;
}

.review-bloc{
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	font-family:europa;
	font-weight: 300;
	margin: 2rem 0;
}

.review-container {
	margin-bottom: 5em;
}

.ratingstars {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.review-name{
	font-size: 1.5rem;
	margin-bottom: 1rem;
	text-align: center;
}

.review-content{
	margin: 1rem 1.5rem 0 1.5rem;
	font-size: 1.2rem;
	line-height: 1.5rem;
}

.content-awards{
	font-family: Europa;
	font-weight: 300;
	font-size: 1.2rem;
	line-height: 1.5rem;
	margin: 2rem 1.5rem 0 1.5rem;
}

.container-list-compet{
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	margin: 2rem 0;
	font-size: 1.2rem;
	font-family: Europa;
	font-weight: 400;
	line-height: 1.5rem;
}

.prizes-container{
	display: grid;
	grid-template-columns: 1fr 1fr;
}

.content-somesay {
	overflow: hidden;
}

.award-img-container{
	display: grid;
	grid-template-columns: 1fr 1fr;		
	margin: 0 1rem 1rem;
}

.award-img{
	width: 90%;
	box-shadow: 1px 3px 5px 0 rgb(0, 0, 0, 0.3);
	margin: 0.5rem;
}

/* CONTACT PAGE */

.contact-middle {
	display: none;
}

.contact-container{
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
}

.title-contact{
	margin: 1rem 0 1.5rem;
	font-size: 2.5rem;
	text-align: center;
}

.contact-single {
	display: flex;
	align-items: center;
	margin-bottom: 0.5em;
	margin-left: 1em;
}

.mobile-contact{
	color: var(--blue);
}

.contact-text {
	font-size: 1.5em;
	text-decoration: none;
	margin-left: 0.5em;
	color: var(--black);
	font-family: Europa;
	font-weight: 300;
}

.container-map{
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin: 2rem 0;
}

.video-contact{
	width: 100%;
}

.mapouter {
	display: flex;
	justify-content: center;
	align-items: center;
}

.gmap_canvas{
	width: 300px;
	height: 250px;
}

.contact-address{
	font-family: Europa;
	font-weight: 300;
	line-height: 2rem;
	font-size: 1.5rem;
	margin-bottom: 1.5rem;
}

.video-contact-cont {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-bottom: 5em;
}

.desktop {
	display: none;
}

/* CONSULTATION PAGES */

.form-consultation{
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.title-label-form{
	margin: 0.6em 0;
}

.label-form{
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	font-family: Europa;
	font-weight: 300;
	font-size: 1.2rem;
	width: 100%;
	margin: 0.5rem 0;
}

.labeldiv {
	display: flex;
	flex-direction: column;
}

.input-form-small{
	height: 50px;
	width: 24em;
	padding: 15px 20px;
	border-radius: 20px;
	border: none;
	box-shadow: 1px 3px 5px 0 rgb(0, 0, 0, 0.3);
	resize: none;
}

.input-form-big{
	height: 100px;
	width: 24em;
	padding: 12px 20px;
	border-radius: 20px;
	border: none;
	box-shadow: 1px 3px 5px 0 rgb(0, 0, 0, 0.3);
	resize: none;
}

.buttons-form-container{
	display: flex;
	flex-direction: row;
	justify-content: space-evenly;
	width: 100%;
	margin: 2rem 0;
}

.buttons-form{
	width: 150px;
}

.text-form{
	font-family: de-walpergens-pica, sans-serif;
	font-size: 2rem;
	margin: 1rem;
}

.formnext {
	width: 6em;
}

.form-onecont {
	display: flex;
	flex-direction: column;
	width: 10em;
	font-size: 1.3em;
}

input[type="file"] {
    display: none;
}

.custom-file-upload {
    display: inline-block;
    cursor: pointer;
}

.icon-cont{
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: white;
	height: 80px;
	width: 17em;
	border-radius: 20px;
	box-shadow: 1px 3px 5px 0 rgb(0, 0, 0, 0.3);
}

.icon-form{
	color: var(--red);
}

::placeholder{
	font-family: europa;
	font-weight: 300;
}

/* Consultation - final stage */

.consultation-final-cont {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
}

.finalcontent {
	height: 90vh;
	display: flex;
	width: 100%;
}

.final-img {
	color: var(--blue);
	margin: 0.4em 0;
}

.finaltext {
	font-size: 1.5em;
	font-family: Europa;
	font-weight: 300;
	text-align: center;
	margin: 0 1em;
}

.final-button-cont {
	margin: 3em 0;
}

.button-final {
	width: 10em;
	cursor: pointer;
}

.consultarea {
    background-image: url('../img/background.png') ;
    background-size: cover;
}

@media screen and (min-width: 768px) {

	/* HOME DESKTOP */

	.imagesection{
		background-image: url(../img/studio-desktop.jpg);
	}

	.main, .content {
		width: 100%;
	}

	.sub-title-mobile{
		display: none;
	}

	.title-home-desktop{
		display: flex;
		justify-content: left;
		font-family: de-walpergens-pica-sc, sans-serif;
		font-size: 2.5rem;
		margin: 2rem 0 2rem 5rem;
	}

	.container-home-content{
		display: grid;
		grid-template-columns: 3fr 4fr;
		margin: 3rem 3.5rem
	}

	.container-home-content:nth-child(2n) {
		grid-template-columns: 3fr 2fr;
	}

	.container-home-content:nth-child(2n) > figure {
		order: 2;
	}

	.container-home-content:nth-child(2n) > article {
		order: 1;
	}

	.ronni-home{
		width: 65%;
	}

	.article-home{
		display: flex;
		flex-direction: column;
		justify-content: center;
		padding: 0 2rem;
	}

	.home-text-p{
		font-size: 1.4rem;
		line-height: 2rem;
	}

	.buttons-container {
		flex-direction: row;
	}

	.button-home{
		width: 25%;
	}

	#home-btn-left {
		margin-right: 3rem;
	}

	.button-home:hover{
		transition: .4s;
		background-color: var(--beige);
		border: 3px solid var(--blue);
		color: var(--blue);
	}

	.price-section {
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		margin-bottom: 2em;
	}

	.price-container {
		width: 70%;
	}

	/* FOOTER DESKTOP */

	.mobile-footer {
		display: none !important;
	}
	
	.desktop-footer {
		display: block !important;
	}

	.footer-middle, .footer-top, .footer-some, .footer-bottom {
		width: auto;
		margin: 0;
	}

	.footersome-heading {
		display: block;
	}

	.footer-main {
		width: 100%;
		justify-content: space-around;
		padding: 1em 5em;
		padding-left: 10em;
	}

	.footer-top {
		width: 75%;
	}

	.footer-bottom {
		width: 25%;
		flex-direction: column;
		justify-content: flex-start;
		align-items: flex-start;
		padding: 0.6em;
	}

	.footer-hours {
		width: 33%;
	}

	.footer-middle {
		width: 66%;
	}
	
	.footer-location-cont, .footer-contact-cont {
		width: 50%;
	}

	.footer-main, .footer-middle {
		flex-direction: row;
	}

	.someleft, .someright {
		margin: 0;
	}

	.footer-hours-head, .footerloc-heading, .footercontact-heading, .footersome-heading {
		font-size: 2em;
	}

	.footer-single-hour, .footerloc-text {
		font-size: 1.2em;
	}

	.footercontact-text {
		font-size: 1.3em;
	}

	/* PORTFOLIO DESKTOP */

	#button3 {
		margin: 0.5em;
		width: auto;
	}
	
	.portfolio-container {
		margin-top: 5em;
		display: grid; 
		grid-auto-columns: 1fr; 
		grid-template-columns: 1fr 1fr 1fr 1fr; 
		grid-template-rows: 1fr; 
		gap: 0px 0px; 
		grid-template-areas: 
		  "portfbtn portfimg portfimg portfimg"; 
	}

	.buttons-container-portfolio {
		grid-area: portfbtn;
	}

	.portfolio-img-container {
		grid-area: portfimg;
	}

	.buttons-container-portfolio {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		padding-left: 2em;

	}

	.portfolio-btn {
		color: var(--black);
		background-color: var(--beige);
		font-family: de-walpergens-pica-sc, sans-serif;
		font-size: 2em;
	}

	.portfolio-img-container {
		grid-template-rows: 1fr 1fr 1fr; 
		gap: 0px 0px; 
		grid-template-areas: 
		". . ."
		". . ."; 
	}

	.portfolio-img {
		width: 22em;
	}

	.underline {
		text-decoration: underline;
	}

	.modalbox {
		width: 40em;
		height: 40em;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.portfolio-full-img {
		margin: 0;
		width: 38em;
	}

	/* PROCEDURES & CARE */
	
	.accordion-container{
		width: 50%;
	}

	.accordion-title{
		padding: 20px;
		font-size: 1.5rem;
	}

	.accordion-content{
		font-size: 1.3em;
		line-height: 1.4em;
	}

	.link-care:hover{
		color: var(--link-hover);
	}

	/* SOME SAY ABOUT US PAGE */

	.prizes-container{
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.reviews-section {
		width: 100%;
		display: flex;
		justify-content: center;
	}

	.review-name {
		font-size: 2em;
		margin: 0.7em 0;
	}

	.review-content {
		font-size: 1.5em;
	}

	.review-container {
		width: 70%;
	}

	.slick-prev:before,
	.slick-next:before {
  		color: var(--black)!important;
		size: 2em!important;
	}

	.content-awards {
		margin: 2em 0;
		font-size: 1.5em;
		text-align: center;
	}

	.container-list-compet {
		font-size: 1.5em;
		line-height: 1.4em;
		margin-bottom: 2em;
	}

	.award-img-container{
		display: grid;
		grid-template-columns: 1fr 1fr 1fr 1fr;		
		margin: 0 4rem 2rem;
	}

	.award-img{
		width: 95%;
		box-shadow: 1px 3px 5px 0 rgb(0, 0, 0, 0.3);
		margin: 0.5rem;
	}

	/* CONTACT desktop */

	.desktop {
		display: block;
	}

	.contact-container {
		display: block;
	}

	.middleline {
		display: block;
		width: 0.1em;
		height: 100%;
		background-color: var(--link-hover);
	}

	.contact-page {
		display: grid; 
		grid-auto-columns: 1fr; 
		grid-template-columns: 1fr 0.2fr 1fr; 
		grid-template-rows: 1fr; 
		gap: 0px 0px; 
		grid-template-areas: 
		"left middle right";
		margin-top: 3em;
		margin-bottom: 5em;
	}

	.contact-middle {
		display: flex;
		justify-content: center;
		grid-area: middle;
	}

	.contact-left {
		grid-area: left;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}

	.contact-right {
		grid-area: right;
		display: flex;
		justify-content: center;
	}

	hr {
		transform: rotate(90deg);
	}

	.gmap_canvas{
		width: 450px;
		height: 350px;
	}

	.video-contact {
		width: 35em;
	}

	.video-contact {
		width: 700px;
		height: 500px;
	}

	.btn-contact {
		margin: 2em;
		font-size: 1.4em;
	}

	.contact-text {
		font-size: 2em;
	}

	.contact-address {
		font-size: 2em;
		line-height: 1.5em;
	}

	.contact-single {
		margin-bottom: 3em;
	}

	/* Consultation desktop */

	.button-final {
		width: 10em;
	}

	.input-form-big:focus, .input-form-small:focus {
		outline: none;
	}

	.buttons-form {
		width: 6em;
	}

	.buttons-form-container {
		justify-content: center;
	}

	#secondinp-btn {
		margin-left: 1em;
	}
}